import qs from "qs";

export const getCategoriesQuery = (locale) => {
  return qs.stringify({
    locale,
    fields: ["name", "slug"],
  });
};

export const getTagsQuery = (locale) => {
  return qs.stringify({
    locale,
    fields: ["name", "slug", "group"],
  });
};

export const getPostsQuery = (
  locale,
  page = 1,
  filters = undefined,
  pageSize = 24,
) => {
  return qs.stringify({
    locale,
    fields: ["title", "slug", "views", "createdAt"],
    pagination: {
      page,
      pageSize,
    },
    populate: {
      author: {
        populate: ["photo"],
      },
      tags: true,
      cover: true,
    },
    filters: filters,
  });
};

export const getPostQuery = (locale) => {
  return qs.stringify({
    locale,
    populate: {
      author: {
        populate: ["photo"],
      },
      tags: true,
      cover: true,
    },
  });
};
